<template>
  <div>
    <div class="columns is-mobile is-multiline">
      <span class="column is-4">
        <b-field>
          <b-input
            v-model="search"
            placeholder="Search..."
            type="search"
            icon="search"
          ></b-input>
        </b-field>
      </span>
      <span class="column is-8">
        <b-field grouped position="is-right">
          <b-select v-model="perPage" @input="onPerPageChange">
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </b-select>
        </b-field>
      </span>
    </div>

    <b-table
      :data="categories && categories.data ? categories.data : []"
      :current-page.sync="currentPage"
      paginated
      backend-pagination
      :total="categories && categories.total ? categories.total : 0"
      :per-page="perPage"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
    >
      <b-table-column
        field="index"
        width="20"
        numeric
        label="No"
        v-slot="props"
      >
        {{ props.index + categories.from }}
      </b-table-column>
      <b-table-column field="name" label="Category" sortable v-slot="props">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column
        field="created_at"
        label="Created At"
        sortable
        v-slot="props"
      >
        {{ props.row.createdAt }}
      </b-table-column>
      <b-table-column
        field="updated_at"
        label="Last Update"
        sortable
        v-slot="props"
      >
        {{ props.row.updatedAt }}
      </b-table-column>
      <b-table-column field="status" label="Status" sortable v-slot="props">
        <template v-if="props.row.deletedAt == null">
          <span class="tag is-success">Active</span>
        </template>
        <template v-else>
          <span class="tag is-warning">Inactive</span>
        </template>
      </b-table-column>
      <b-table-column field="action" centered label="Action" v-slot="props">
        <template v-if="props.row.deletedAt == null">
          <b-button
            type="icon-button"
            icon-left="pencil-alt"
            @click="editCategory(props.row.id, props.row.name)"
          ></b-button>
          <b-button
            type="icon-button"
            icon-left="archive"
            title="Inactive Assessment"
            @click="inactivePopup(props.row.id)"
          ></b-button>
        </template>
        <template v-else>
          <b-button
            type="icon-button"
            icon-left="pencil-alt"
            @click="editCategory(props.row.id, props.row.name)"
          ></b-button>

          <b-button
            type="icon-button"
            icon-left="undo-alt"
            @click="restorePopup(props.row.id)"
          ></b-button>

          <b-button
            type="icon-button"
            icon-left="trash"
            @click="deletePopup(props.row.id)"
          ></b-button>
        </template>
      </b-table-column>
      <template slot="bottom-left">
        {{ categories && categories.from ? categories.from : 0 }}
        -
        {{ categories && categories.to ? categories.to : 0 }}
        from
        {{ categories && categories.total ? categories.total : 0 }}
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="frown" size="is-large"></b-icon>
            </p>
            <p>Data not found.</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import debounce from 'lodash/debounce'

export default {
  data() {
    const today = new Date()
    return {
      currentDate: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      ),
      isLoading: false,
      isLoadingTable: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      defaultSortOrder: 'desc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 10,
      activeTab: 0,
      sortField: 'created_at',
      sortOrder: 'DESC',
      page: 1,
      search: null,
      status: 'All',
      link: '',
      message: {
        text: '',
        type: '',
      },
      isModalPreviewActive: false,
      isModalLinkActive: false,
    }
  },
  mounted() {
    this.fetchData(
      this.perPage,
      this.currentPage,
      this.sortField,
      this.sortOrder,
      this.search,
      this.status
    )
  },
  methods: {
    ...mapActions({
      fetchCategories: 'newCategory/fetchCategories',
      inactiveCategoryData: 'newCategory/inactiveCategory',
      deleteCategoryData: 'newCategory/deleteCategory',
      restoreCategoryData: 'newCategory/restoreCategory',
    }),
    reload() {
      this.fetchData(
        this.perPage,
        this.currentPage,
        this.sortField,
        this.sortOrder,
        this.search,
        this.status
      )
    },
    // For search category
    searchCategory: debounce(function(value) {
      if (value) {
        this.search = value
        this.fetchData(
          this.perPage,
          this.page,
          this.sortField,
          this.sortOrder,
          this.search,
          this.status
        )
      } else {
        this.search = null
        this.fetchData(
          this.perPage,
          this.page,
          this.sortField,
          this.sortOrder,
          this.search,
          this.status
        )
      }
    }, 500),

    // For inactive popup
    inactivePopup(id) {
      this.$buefy.dialog.confirm({
        title: 'Inactive Category',
        message: `Are you sure want to inactive this category?`,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, inactive it!',
        type: 'is-hcc',
        onConfirm: () => this.inactiveCategory(id),
      })
    },

    // For inactive category
    async inactiveCategory(id) {
      this.isLoading = true

      try {
        await this.inactiveCategoryData(id)

        this.success('Inactive Category Success')
      } catch (err) {
        this.danger(err)
      }

      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.status
      )

      this.isLoading = false
    },

    // For restore popup
    restorePopup(id) {
      this.$buefy.dialog.confirm({
        title: 'Restore Category',
        message: `Are you sure want to restore this category?`,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, restore it!',
        type: 'is-hcc',
        onConfirm: () => this.restoreCategory(id),
      })
    },

    // For restore category
    async restoreCategory(id) {
      this.isLoading = true

      try {
        await this.restoreCategoryData(id)

        this.success('Restore Category Success')
      } catch (err) {
        this.danger(err)
      }

      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.status
      )

      this.isLoading = false
    },

    // For delete popup
    deletePopup(id) {
      this.$buefy.dialog.confirm({
        title: 'Delete Category',
        message: `Are you sure want to delete this category?`,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, delete it!',
        type: 'is-danger',
        onConfirm: () => this.deleteCategory(id),
      })
    },

    // For delete category
    async deleteCategory(id) {
      this.isLoading = true

      try {
        await this.deleteCategoryData(id)

        this.success('Delete Category Success')
      } catch (err) {
        this.danger(err)
      }

      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.status
      )

      this.isLoading = false
    },

    // For fetching data
    async fetchData(perPage, page, sortField, sortOrder, search, status) {
      let data = {
        perPage: perPage,
        page: page,
        sortField: sortField,
        sortOrder: sortOrder,
        search: search,
        status: status,
      }

      this.isLoadingTable = false
      try {
        await this.fetchCategories(data)
      } catch (err) {
        this.danger(err)
      }
      this.isLoadingTable = true
    },

    editCategory(id, name) {
      this.$emit('edit-category', { id, name })
    },

    // For Page Change
    onPageChange(page) {
      this.currentPage = page
      this.page = page
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.status
      )
    },

    // For Sorting Data
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.status
      )
    },

    // For per page change
    onPerPageChange(value) {
      this.perPage = value
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.status
      )
    },

    // For success alert
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },

    // For danger/error alert
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },
  },
  watch: {
    search: function(val) {
      this.searchCategory(val)
    },
  },
  computed: {
    ...mapGetters({
      categories: 'newCategory/getNewCategories',
    }),
  },
}
</script>
<style scoped>
.modal-copy-link {
  width: 500px !important;
}
</style>
