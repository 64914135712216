<template>
  <div class="main index" v-if="!isLoading">
    <!-- For title -->
    <div class="columns is-mobile is-multiline">
      <div class="column is-12">
        <h1 class="is-size-3"><b>Category Bank Soal</b></h1>
      </div>
      <div class="column is-12">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li>
              <h3 class="is-size-6">Bank Soal</h3>
            </li>
            <li>
              <h3 class="is-size-6 breadcrumb-item">Category Bank Soal</h3>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <!-- For table -->
    <div class="columns is-mobile">
      <div class="column is-12">
        <div class="card card-table">
          <div class="columns is-mobile is-multiline">
            <div class="column is-10"></div>
            <div class="column is-2">
              <b-field grouped position="is-right">
                <b-button
                  type="is-hcc"
                  title="Create Category"
                  @click="isModalOpen = true"
                >
                  Create
                </b-button>
              </b-field>
            </div>
          </div>
          <b-tabs v-model="activeTab">
            <b-tab-item>
              <template slot="header">
                <span class="tab">Active</span>
              </template>
              <CategoryTableActive
                v-if="activeTab == 0"
                ref="tableActive"
                v-on:edit-category="editCategory"
              />
            </b-tab-item>
            <b-tab-item>
              <template slot="header">
                <span class="tab">Inactive</span>
              </template>
              <CategoryTableInactive
                v-if="activeTab == 1"
                ref="tableInActive"
                v-on:edit-category="editCategory"
              />
            </b-tab-item>
            <b-tab-item>
              <template slot="header">
                <span class="tab">All Category</span>
              </template>
              <CategoryTableAllType
                v-if="activeTab == 2"
                ref="tableAll"
                v-on:edit-category="editCategory"
              />
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
    </div>
    <!-- For Create -->
    <NewModal
      v-if="isModalOpen"
      :is-modal-open="isModalOpen"
      :title-modal="editData == null ? 'Add Category' : 'Edit Category'"
      :on-close-modal-callback="closeModal"
    >
      <template v-slot:content>
        <FormAddCategory v-on:close-modal="closeModal" :edit-data="editData" />
      </template>
    </NewModal>
  </div>
</template>

<script>
import CategoryTableActive from '@/components/V2/Category/CategoryTableActive'
import CategoryTableInactive from '@/components/V2/Category/CategoryTableInactive'
import CategoryTableAllType from '@/components/V2/Category/CategoryTableAllType'
import FormAddCategory from '@/views/v2/category/FormAddCategory'
import NewModal from '@/components/V2/NewModal'

export default {
  name: 'index-category',
  components: {
    CategoryTableActive,
    CategoryTableInactive,
    CategoryTableAllType,
    NewModal,
    FormAddCategory,
  },
  data() {
    return {
      activeTab: 0,
      isLoading: false,
      isModalOpen: false,
      editData: null,
    }
  },
  methods: {
    closeModal(event) {
      if (event.reload != undefined) {
        if (this.$refs.tableActive !== undefined) {
          this.$refs.tableActive.reload()
        }
        if (this.$refs.tableInActive !== undefined) {
          this.$refs.tableInActive.reload()
        }
        if (this.$refs.tableAll !== undefined) {
          this.$refs.tableAll.reload()
        }
      }
      this.isModalOpen = false
      this.editData = null
    },
    editCategory(event) {
      this.editData = event
      this.isModalOpen = true
    },
  },
}
</script>
